import React, { useState } from 'react'
import { connect } from 'react-redux';
import { agregarProducto } from '../../actions/productos';
import PropTypes from 'prop-types';

const FormProducto = ({ agregarProducto, history }) =>{
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Hacemos las validaciones necesarias
        try {
            await agregarProducto(productoData);
            history.push('/productos');
        } catch (error) {
            return false;
        }
    }
    
    const [productoData, setProductoData ] = useState({
        nombre: '',
        abreviatura: '',
        costo: '',
        duracion: ''
    });
    
    const { nombre, abreviatura, costo, duracion } = productoData;
    
    const onChange = (e) => {
        setProductoData({
            ...productoData, [e.target.name]: e.target.value
        })
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Crear Producto</h3>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e) } value={nombre}></input>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Abreviatura" name="abreviatura" onChange={e => onChange(e) } value={abreviatura}></input>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Costo" name="costo" onChange={e => onChange(e) } value={costo}></input>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Duración" name="duracion" onChange={e => onChange(e) } value={duracion}></input>
                </div>
                <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>Crear</button>
            </form>
        </div>
    )
}

FormProducto.propTypes = {
    agregarProducto: PropTypes.func.isRequired,
}
    

export default connect(null, {
    agregarProducto
})(FormProducto);