import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { agregarUsuario } from '../../actions/usuarios';
import PropTypes from 'prop-types';

const FormUsuario = ({ history, agregarUsuario }) => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        agregarUsuario({
            ...usuarioData
        })
        history.push('/usuarios');
    }

    const [usuarioData, setUsuarioData] = useState({
        nombre: '',
        email: '',
        password: ''
    });

    const { nombre, email, password } = usuarioData;

    const onChange = (e) => {
        setUsuarioData({
            ...usuarioData, [e.target.name]: e.target.value
        })
    }

    return (
        <div className="row pb-5">
            <form className="col-md-10 offset-md-1">
                <h3 className="mb-3 mt-5">Crear Usuario</h3>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e)} value={nombre}></input>
                </div>
                <div className="form-group">
                    <input type="email" className="form-control" placeholder="Correo" name="email" onChange={e => onChange(e)} value={email}></input>
                </div>
                <div className="form-group">
                    <input type="password" className="form-control" placeholder="Contraseña" name="password" onChange={e => onChange(e)} value={password}></input>
                </div>
                <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>Crear</button>
            </form>
        </div>
    )
}

FormUsuario.propTypes = {
    agregarUsuario: PropTypes.func.isRequired,
}


export default connect(null, {
    agregarUsuario
})(FormUsuario);