import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOADED, AUTH_ERROR, LOGOUT, EDITAR_PERFIL } from './types';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

// LOAD USER
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/auth/`);
        dispatch({
            type: USER_LOADED,
            payload: res.data.user
        })
    } catch (error) {
        dispatch({
            type: AUTH_ERROR
        })
    }

}

// LOGIN USER
export const login = ({ email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ email, password});
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/auth/`, body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
        dispatch({
            type: LOGIN_FAILED
        })
    }
}

// Logout
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}

export const editarUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(usuario);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/editar/`, body, config);
        dispatch({
            type: EDITAR_PERFIL,
            payload: res.data.user
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}