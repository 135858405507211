
// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT'
export const EDITAR_PERFIL = 'EDITAR_PERFIL'

// Productos
export const AGREGAR_PRODUCTO = 'AGREGAR_PRODUCTO';
export const ELIMINAR_PRODUCTO = 'ELIMINAR_PRODUCTO';
export const EDITANDO_PRODUCTO = 'EDITANDO_PRODUCTO';
export const EDITAR_PRODUCTO = 'EDITAR_PRODUCTO';
export const BUSCAR_PRODUCTOS = 'BUSCAR_PRODUCTOS';

// Compras
export const AGREGAR_COMPRA = 'AGREGAR_COMPRA';
export const ELIMINAR_COMPRA = 'ELIMINAR_COMPRA';
export const EDITANDO_COMPRA = 'EDITANDO_COMPRA';
export const EDITAR_COMPRA = 'EDITAR_COMPRA';
export const BUSCAR_COMPRAS = 'BUSCAR_COMPRAS';

// Gestión
export const GESTIONAR_COMPRAS = 'GESTIONAR_COMPRAS';
export const COBRAR_COMPRA = 'COBRAR_COMPRA';
export const REALIZAR_COMPRA = 'REALIZAR_COMPRA';
export const FINALIZAR_COMPRA = 'FINALIZAR_COMPRA';

// Usuarios
export const AGREGAR_USUARIO = 'AGREGAR_USUARIO';
export const ELIMINAR_USUARIO = 'ELIMINAR_USUARIO';
export const EDITANDO_USUARIO = 'EDITANDO_USUARIO';
export const BUSCAR_USUARIOS = 'BUSCAR_USUARIOS';
export const EDITAR_USUARIO = 'EDITAR_USUARIO';

// DASHBOARD
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD'

// Loader
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
