import { AGREGAR_PRODUCTO, EDITAR_PRODUCTO, EDITANDO_PRODUCTO, ELIMINAR_PRODUCTO, BUSCAR_PRODUCTOS } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

export const agregarProducto = (producto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(producto);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/productos/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_PRODUCTO,
            payload: res.data.producto
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const eliminarProducto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id});
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/productos/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_PRODUCTO,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const buscarProductos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/productos/`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: BUSCAR_PRODUCTOS,
            payload: res.data.productos
        })
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editarProducto = (producto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(producto);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/productos/editar/`, body, config);
        dispatch({
            type: EDITAR_PRODUCTO,
            payload: producto.id
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editandoProducto = (id) => dispatch => {
    dispatch({
        type: EDITANDO_PRODUCTO,
        payload: id
    })
}