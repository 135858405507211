import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { eliminarCompra, buscarCompras, editandoCompra } from '../../actions/compras';

const Compras = ({ history }) => {

    const dispatch = useDispatch();
    const compras = useSelector(state => state.compras);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.cliente}</td>
                            <td>{item.producto}</td>
                            <td>{item.precio}</td>
                            <td>{item.pago}</td>
                            <td>{item.status}</td>
                            <td>
                                <button className="btn btn-link text-primary" onClick={() => editar(item._id)}>Editar</button>
                            </td>
                            <td>
                                <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarCompra(id));
    }

    const editar = (id) => {
        dispatch(editandoCompra(id));
        history.push('/compras/editar');
    }

    useEffect(() => {
        dispatch(buscarCompras());
    }, [])

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Compras</h3>
                        <p className="text-muted">{compras.compras?.length} compra{compras.compras?.length == 1 ? '' : 's'} registrada{compras.compras?.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        <Link to="/compras/nuevo" className="btn btn-primary text-right">Nueva compra</Link>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Cliente</th>
                            <th scope="col">Producto</th>
                            <th scope="col">Costo</th>
                            <th scope="col">Pago</th>
                            <th scope="col">Status</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(compras.compras !== undefined ? compras.compras : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Compras;