import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { gestionarCompras, cobrarCompra, realizarCompra, finalizarCompra } from '../../actions/compras';

const Gestion = ({ history }) => {

    const dispatch = useDispatch();
    const compras = useSelector(state => state.compras);

    const [cobroModal, setCobroModal] = useState(false);
    const [realizacionModal, setRealizacionModal] = useState(false);
    const [compraSeleccionada, setCompraSeleccionada] = useState(undefined);

    const [compraCobrarData, setCompraCobrarData ] = useState({
        id: null,
        detalle_pago: ''
    });

    const [compraRealizarData, setCompraRealizarData ] = useState({
        id: null,
        id_producto: null,
        fecha_inicio: ''
    });

    const tableRowPago = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.cliente}</td>
                            <td>{item.producto}</td>
                            <td>{item.precio}</td>
                            <td>{new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>
                                <button className="btn btn-link text-primary" onClick={() => detalles(item._id)}>Detalles</button>
                            </td>
                            <td>
                                <button className="btn btn-link text-danger" onClick={() => handleCobrar(item)}>Cobrar</button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const tableRowRealizacion = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.cliente}</td>
                            <td>{item.producto}</td>
                            <td>{item.abreviatura}</td>
                            <td>{item.puerto}</td>
                            <td>{new Date(item.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>
                                <button className="btn btn-link text-primary" onClick={() => detalles(item._id)}>Detalles</button>
                            </td>
                            <td>
                                <button className="btn btn-link text-danger" onClick={() => handleRealizar(item)}>Realizar</button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const tableRowFinalizacion = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.cliente}</td>
                            <td>{item.producto}</td>
                            <td><a href={'http://'+item.ruta} target="_blank">{item.ruta}</a></td>
                            <td>{new Date(item.fecha_fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td>
                                <button className="btn btn-link text-primary" onClick={() => detalles(item._id)}>Detalles</button>
                            </td>
                            <td>
                                <button className="btn btn-link text-danger" onClick={() => handleFinalizar(item._id)}>Finalizar</button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const onChangeCobrar = (e) => {
        setCompraCobrarData({
            ...compraCobrarData, [e.target.name]: e.target.value
        })
    }

    const handleCobrar = item => {
        setCobroModal(true);
        setCompraSeleccionada(item);
        setCompraCobrarData({
            ...compraCobrarData,
            id: item._id
        });
    }

    const handleCobro = () => {
        dispatch(cobrarCompra(compraCobrarData));
        setCobroModal(false);
        setCompraCobrarData({
            id: null,
            detalle_pago: ''
        });
    }

    const handleRealizar = item => {
        setRealizacionModal(true);
        setCompraSeleccionada(item);
        setCompraRealizarData({
            ...compraRealizarData,
            id: item._id,
            id_producto: item.id_producto,
            fecha_inicio: item.fecha_inicio
        });
    }

    const handleRealizacion = () => {
        dispatch(realizarCompra(compraRealizarData));
        setRealizacionModal(false);
        setCompraRealizarData({
            id: null,
            id_producto: null,
            fecha_inicio: ''
        });
    }

    const handleFinalizar = id => {
        dispatch(finalizarCompra({id}));
    }

    const eliminar = (id) => {
        //dispatch(eliminarCompra(id));
    }

    const detalles = (id) => {
        //dispatch(editandoCompra(id));
        history.push('/gestion/detalles');
    }

    useEffect(() => {
        dispatch(gestionarCompras());
    }, [])

    return (
        <Fragment>
            {/* Modal de cobro */}
            <Modal show={cobroModal} onHide={() => {
                setCobroModal(false);
                setCompraCobrarData({
                    id: null,
                    detalle_pago: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Información de cobro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        compraSeleccionada && <div className="row">
                            <p className="col-12 my-0 font-weight-bold">Cliente</p>
                            <p className="col-12 mt-0 mb-2">{compraSeleccionada.cliente}</p>
                            <p className="col-12 my-0 font-weight-bold">Monto</p>
                            <p className="col-12 mt-0 mb-2">{compraSeleccionada.precio}</p>
                            <div className="col-12 form-group">
                                <label className="font-weight-bold">Detalles del pago:</label>
                                <textarea className="form-control" rows="3" placeholder="Detalles del pago" name="detalle_pago" onChange={ e => onChangeCobrar(e) }></textarea>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCobro}>
                        Realizar cobro
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de realización */}
            <Modal show={realizacionModal} onHide={() => {
                setRealizacionModal(false);
                setCompraRealizarData({
                    id: null,
                    id_producto: null,
                    fecha_inicio: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Inicio de producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        compraSeleccionada && <div className="row">
                            <p className="col-12 my-0 font-weight-bold">Cliente</p>
                            <p className="col-12 mt-0 mb-2">{compraSeleccionada.cliente}</p>
                            <p className="col-12 my-0 font-weight-bold">Tipo de producto</p>
                            <p className="col-12 mt-0 mb-2">{compraSeleccionada.producto}</p>
                            <p className="col-12 my-0 font-weight-bold">Fecha de inicio</p>
                            <p className="col-12 mt-0 mb-2">{new Date(compraSeleccionada.fecha_inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                            <p className="col-12 my-0 font-weight-bold">Puerto</p>
                            <p className="col-12 mt-0 mb-2">{compraSeleccionada.puerto}</p>
                            <p className="col-12 my-0 font-weight-bold">Ruta</p>
                            <p className="col-12 mt-0 mb-2"><a href={'http://'+compraSeleccionada.ruta} target="_blank">{compraSeleccionada.ruta}</a></p>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleRealizacion}>
                        Producto iniciado
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-12">
                            <h3>Pendientes de pago</h3>
                            <p className="text-muted">{compras.compras_cobrar?.length} compra{compras.compras_cobrar?.length == 1 ? '' : 's'} pendiente{compras.compras_cobrar?.length == 1 ? '' : 's'}</p>
                        </div>
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Cliente</th>
                                <th scope="col">Producto</th>
                                <th scope="col">Costo</th>
                                <th scope="col">Fecha de inicio</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            tableRowPago(compras.compras_cobrar !== undefined ? compras.compras_cobrar : [])
                        }
                    </table>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-12">
                            <h3>Pendientes de realización</h3>
                            <p className="text-muted">{compras.compras_realizar?.length} compra{compras.compras_realizar?.length == 1 ? '' : 's'} pendiente{compras.compras_realizar?.length == 1 ? '' : 's'}</p>
                        </div>
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Cliente</th>
                                <th scope="col">Producto</th>
                                <th scope="col">Abreviatura</th>
                                <th scope="col">Puerto</th>
                                <th scope="col">Fecha de inicio</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            tableRowRealizacion(compras.compras_realizar !== undefined ? compras.compras_realizar : [])
                        }
                    </table>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-12">
                            <h3>Pendientes de finalización</h3>
                            <p className="text-muted">{compras.compras_finalizar?.length} compra{compras.compras_finalizar?.length == 1 ? '' : 's'} pendiente{compras.compras_finalizar?.length == 1 ? '' : 's'}</p>
                        </div>
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Cliente</th>
                                <th scope="col">Producto</th>
                                <th scope="col">Ruta</th>
                                <th scope="col">Fecha de fin</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            tableRowFinalizacion(compras.compras_finalizar !== undefined ? compras.compras_finalizar : [])
                        }
                    </table>
                </div>
            </div>
        </Fragment>
    )
}


export default Gestion;