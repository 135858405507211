import { AGREGAR_PRODUCTO, EDITAR_PRODUCTO, ELIMINAR_PRODUCTO, BUSCAR_PRODUCTOS, EDITANDO_PRODUCTO } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case AGREGAR_PRODUCTO:
            return [
                ...state, payload
            ];
        case EDITAR_PRODUCTO:
            state.find( producto => producto._id === payload).editing = false;
            return state;
        case EDITANDO_PRODUCTO:
            state.find( producto => producto._id === payload).editing = true;
            return state;
        case ELIMINAR_PRODUCTO:
            return state.filter( producto => producto._id !== payload.id )
        case BUSCAR_PRODUCTOS:
            return payload;
        default:
            return state;
    }
}