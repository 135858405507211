import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Login
import Login from './components/auth/Login'

// Layout
import NotFoundPage from './components/routing/NotFoundPage'
import PrivateRoute from './components/routing/PrivateRoute'
import Layout from './components/layout/Layout';
import Alert from './components/layout/Alert'

import Dashboard from './components/dashboard/Dashboard'

// Usuarios
import Perfil from './components/usuarios/Perfil'
import Usuarios from './components/usuarios/Usuarios'
import FormUsuario from './components/usuarios/FormUsuario'
import EditUsuario from './components/usuarios/EditUsuario'

// Productos
import Productos from './components/productos/Productos'
import FormProducto from './components/productos/FormProducto'
import EditProducto from './components/productos/EditProducto'

// Compras
import Compras from './components/compras/Compras'
import FormCompra from './components/compras/FormCompra'
import EditCompra from './components/compras/EditCompra'

// Gestión
import Gestion from './components/gestion/Gestion'

// Redux
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth'


const App = () => {

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    useEffect(() => {
        store.dispatch(loadUser());
    }, [])


    return (<Provider store={store}>
        <Router>
            <Fragment>
                <Layout></Layout>
                <section className="container-fluid" style={{ paddingLeft: '64px' }}>
                    <Alert></Alert>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                        
                        {/* Usuarios */}
                        <PrivateRoute exact path="/usuarios" component={Usuarios} />
                        <PrivateRoute exact path="/usuarios/nuevo" component={FormUsuario} />
                        <PrivateRoute exact path="/usuarios/editar/:id" component={EditUsuario} />

                        {/* PRODUCTOS */}
                        <PrivateRoute exact path="/productos" component={Productos} />
                        <PrivateRoute exact path="/productos/nuevo" component={FormProducto} />
                        <PrivateRoute exact path="/productos/editar" component={EditProducto} />

                        {/* COMPRAS */}
                        <PrivateRoute exact path="/compras" component={Compras} />
                        <PrivateRoute exact path="/compras/nuevo" component={FormCompra} />
                        <PrivateRoute exact path="/compras/editar" component={EditCompra} />

                        {/* GESTION */}
                        <PrivateRoute exact path="/gestion" component={Gestion} />

                        {/* Perfil */}
                        <PrivateRoute exact path="/perfil" component={Perfil} />

                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </section>
            </Fragment>
        </Router>
    </Provider>)
}

export default App;
