import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect, useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import { agregarCompra } from '../../actions/compras';

registerLocale('es', es);

const FormCompra = ({ agregarCompra, history }) =>{
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Hacemos las validaciones necesarias
        try {
            await agregarCompra(compraData);
            history.push('/compras');
        } catch (error) {
            return false;
        }
    }

    const productos = useSelector(state => state.productos);
    
    const [compraData, setCompraData ] = useState({
        cliente: '',
        producto: null,
        usuario: '',
        password: '',
        observaciones: '',
        precio: '',
        fecha_inicio: ''
    });
    
    const { cliente, producto, usuario, password, observaciones, precio, fecha_inicio } = compraData;
    
    const onChange = (e) => {
        setCompraData({
            ...compraData, [e.target.name]: e.target.value
        })
    }

    const handleProduct = () => {
        let producto = document.getElementById('select_productos');

        if (producto.value != 0) {
            const producto_elegido = productos.filter(product => product._id === producto.value);

            if(producto_elegido[0].acceso === true){
                document.getElementById('contenedor-usuario').classList.remove('d-none');
            }else{
                document.getElementById('contenedor-usuario').classList.add('d-none');
            }
            
            setCompraData({
                ...compraData,
                usuario: '',
                password: '',
                producto: producto.value,
                precio: producto_elegido[0].costo
            });
        }else{
            setCompraData({
                ...compraData,
                producto: null,
                precio: ''
            });
        }
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Crear Compra</h3>
                <div className="row">
                    <div className="col-md-8 form-group">
                        <label className="font-weight-bold">Nombre del cliente</label> <br></br>
                        <input type="text" className="form-control" placeholder="Cliente" name="cliente" onChange={e => onChange(e) } value={cliente}></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Tipo de producto</label> <br></br>
                        <select id="select_productos" className="form-control" onChange={ handleProduct }>
                            <option value={0}>Elige una opción</option>
                            {
                                productos.length > 0 ?
                                    productos.map(producto => {
                                        return(
                                            <option key={producto._id} value={ producto._id }>
                                                { producto.nombre }
                                            </option>
                                        )
                                    })
                                : ''
                            } 
                        </select>
                    </div>
                </div>
                <div id="contenedor-usuario" className="row d-none">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Usuario</label> <br></br>
                        <input type="text" className="form-control" placeholder="Usuario" name="usuario" onChange={e => onChange(e) } value={usuario}></input>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Contraseña</label> <br></br>
                        <input type="text" className="form-control" placeholder="Contraseña" name="password" onChange={e => onChange(e) } value={password}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 form-group">
                        <label className="font-weight-bold">Precio:</label>
                        <input type="text" id="input_precio" className="form-control" placeholder="Precio" name="precio" onChange={ e => onChange(e) } value={ precio || '' }></input>
                    </div>
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Fecha requerida:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ fecha_inicio ? new Date(fecha_inicio) : '' } onChange={ date => setCompraData({ ...compraData, fecha_inicio: date })}/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Observaciones:</label>
                    <textarea className="form-control" value={ observaciones || '' } rows="4" placeholder="Observaciones" name="observaciones" onChange={ e => onChange(e) }></textarea>
                </div>
                <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>Crear</button>
            </form>
        </div>
    )
}

FormCompra.propTypes = {
    agregarCompra: PropTypes.func.isRequired,
}
    

export default connect(null, {
    agregarCompra
})(FormCompra);