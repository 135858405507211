import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { eliminarProducto, buscarProductos, editandoProducto } from '../../actions/productos';

const Productos = ({ history }) => {

    const dispatch = useDispatch();
    const productos = useSelector(state => state.productos);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.nombre}</td>
                            <td>{item.abreviatura}</td>
                            <td>{item.costo}</td>
                            <td>{item.duracion}</td>
                            <td>{item.acceso === true ? 'Si' : 'No'}</td>
                            <td>
                                <button className="btn btn-link text-primary" onClick={() => editar(item._id)}>Editar</button>
                            </td>
                            <td>
                                <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarProducto(id));
    }

    const editar = (id) => {
        dispatch(editandoProducto(id));
        history.push('/productos/editar');
    }

    useEffect(() => {
        dispatch(buscarProductos());
    }, [])

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Productos</h3>
                        <p className="text-muted">{productos.length} producto{productos.length == 1 ? '' : 's'} registrado{productos.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        <Link to="/productos/nuevo" className="btn btn-primary text-right">Agregar Producto</Link>
                    </div>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Abreviatura</th>
                            <th scope="col">Costo</th>
                            <th scope="col">Duración</th>
                            <th scope="col">Acceso</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        tableRow(productos!== undefined ? productos : [])
                    }
                </table>
            </div>
        </div>
    )
}


export default Productos;