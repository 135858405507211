import { combineReducers } from 'redux';
import alert from './alert'
import auth from './auth'
import usuarios from './usuarios'
import productos from './productos'
import compras from './compras'
import dashboard from './dashboard'
import loader from './loader'

export default combineReducers({
    alert, auth, usuarios, productos, compras, dashboard, loader
})