import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { buscarUsuarios, eliminarUsuario, editandoUsuario } from '../../actions/usuarios';
import { Link } from 'react-router-dom';

const Usuarios = ({ history }) => {

    const user = useSelector(state => state.auth.user);
    const usuarios = useSelector(state => state.usuarios);

    const dispatch = useDispatch()
    const tableRow = (items) => {
        return (
            <tbody>
                {items.map((item) => {
                    let fecha = new Date(item.date)
                    if (user && user._id !== item._id) {
                        return (<Fragment key={item._id}>
                            <tr>
                                <td>{item.nombre}</td>
                                <td>{item.email}</td>
                                <td>{fecha.getDay()}/{fecha.getMonth() + 1}/{fecha.getFullYear()}</td>
                                <td>
                                    <Link to={`/usuarios/editar/${item._id}`}>Editar...</Link>
                                </td>
                                <td>
                                    <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                </td>
                            </tr>
                        </Fragment>)
                    } else {
                        return (<Fragment key={item._id}>
                            <tr className="text-muted">
                                <td>{item.nombre}</td>
                                <td>{item.email}</td>
                                <td>{fecha.getDay()}/{fecha.getMonth() + 1}/{fecha.getFullYear()}</td>
                                <td colSpan="3" className="text-center">
                                    <Link to="/perfil" className="btn btn-link text-secondary">Ver perfil</Link>
                                </td>
                            </tr>
                        </Fragment>)

                    }

                })}
            </tbody>
        );
    }

    const eliminar = id => {
        dispatch(eliminarUsuario(id));
    }

    const editar = id => {
        dispatch(editandoUsuario(id));
        history.push('/usuarios/editar');
    }

    useEffect(() => {
        dispatch(buscarUsuarios());
    }, [])

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Usuarios</h3>
                        <p className="text-muted"> {usuarios.length} usuario{usuarios.length == 1 ? '' : 's'} registrado{usuarios.length == 1 ? '' : 's'}</p>
                    </div>
                    <div className="col-md-4 text-right">
                        <Link to="/usuarios/nuevo" className="btn btn-primary text-right">Agregar usuario</Link>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Correo</th>
                                <th scope="col">Fecha de registro</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                            {
                                tableRow(usuarios!== undefined ? usuarios : [])
                            }
                    </table>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    usuarios: state.usuarios,
    auth: state.auth
})

export default Usuarios;