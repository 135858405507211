import axios from 'axios';
import { LOAD_DASHBOARD } from './types';
import { changeLoader } from './loader';

// LOAD USER
export const loadDashboard = () => async dispatch => {
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/dashboard`);
        dispatch(changeLoader(false));
        dispatch({
            type: LOAD_DASHBOARD,
            payload: {
                info: res.data
            }
        })
    } catch (error) {
        dispatch(changeLoader(false));
    }
}