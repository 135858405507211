import React, { useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { editarProducto } from '../../actions/productos';

const EditProducto = ({ productos, history, editarProducto  }) =>{
    
    const producto = productos.find( producto => producto.editing)

    const handleSubmit = async (e) => {
        e.preventDefault();
        await editarProducto(productoData)
        history.push('/productos');
    }
    
    const [ productoData, setProductoData ] = useState({
        id: producto._id,
        nombre: producto.nombre,
        abreviatura: producto.abreviatura,
        costo: producto.costo,
        duracion: producto.duracion
    });
    
    const onChange = (e) => {
        setProductoData({
            ...productoData, [e.target.name]: e.target.value
        })
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Editar Producto</h3>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e) } value={productoData.nombre}></input>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Abreviatura" name="abreviatura" onChange={e => onChange(e) } value={productoData.abreviatura}></input>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Costo" name="costo" onChange={e => onChange(e) } value={productoData.costo}></input>
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Duración" name="duracion" onChange={e => onChange(e) } value={productoData.duracion}></input>
                </div>
                <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>Guardar</button>
            </form>
        </div>
    )
}

EditProducto.propTypes = {
    productos: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    productos: state.productos
})

export default connect(mapStateToProps, {
    editarProducto
})(EditProducto);