import React, { useState, useEffect } from 'react'
import { editarUsuario } from '../../actions/usuarios';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


const EditUsuario = ({ history }) => {

    const usuarios = useSelector(state => state.usuarios)
    const dispatch = useDispatch();

    const { id } = useParams();
    const [usuario, setUsuario] = useState(usuarios.find(usuario => usuario._id == id));

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(editarUsuario(usuario))
        history.push('/usuarios');
    }

    const onChange = (e) => {
        setUsuario({
            ...usuario, [e.target.name]: e.target.value
        })
    }

    if (!usuario) {
        history.push('/usuarios');
        return <div></div>
    } else {
        return (
            <div className="row pb-5">
                <form className="col-md-10 offset-md-1">
                    <h3 className="mb-3 mt-5">Editar Usuario</h3>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e)} value={usuario.nombre}></input>
                    </div>
                    <div className="form-group">
                        <input type="email" className="form-control" placeholder="Correo" name="email" onChange={e => onChange(e)} value={usuario.email}></input>
                    </div>
                    <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>Guardar</button>
                </form>
            </div>
        )
    }
}

export default EditUsuario;