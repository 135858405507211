import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { loadDashboard } from '../../actions/dashboard'

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#eb6363' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 20,
        margin: 0,
        fontWeight: 'bold'
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    buttonIcon: {
        fontSize: 30,
    },
    bgImg: {
        height: '40vw',
        opacity: 0.08,
        position: 'absolute',
        left: '-320px',
        top: '10vh'
    },
}

const Dashboard = () => {

    const user = useSelector(state => state.auth.user)
    const dashboard = useSelector(state => state.dashboard)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDashboard());
    }, [])

    return (
        dashboard.info ? <div className="row">
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-10 offset-md-1 mt-3">
                <div className="row">
                    <div className="col-md-2 pr-5">
                        <div className="row">
                            <div className="col-md-12 py-3">
                                <a href="/compras/nuevo" className="btn btn-light btn-block">
                                    <div className="col-md-12 py-2">
                                        <i className="fa fa-file-upload" style={styles.buttonIcon} />
                                    </div>
                                    Nueva compra
                                </a>
                            </div>
                            <div className="col-md-12 py-3">
                                <a href="/compras" className="btn btn-light btn-block">
                                    <div className="col-md-12 py-2">
                                        <i className="fa fa-shopping-cart" style={styles.buttonIcon} />
                                    </div>
                                    Compras
                                </a>
                            </div>
                            <div className="col-md-12 py-3">
                                <a href="/gestion" className="btn btn-light btn-block">
                                    <div className="col-md-12 py-2">
                                        <i className="fa fa-money-check-alt" style={styles.buttonIcon} />
                                    </div>
                                    Gestión
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <h4 style={styles.titulo} className="mb-4">
                                    Bievenido {user.nombre}
                                </h4>
                            </div>
                            <div className="col-md-5">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Ingresos</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-6">
                                                <p style={styles.infoNumero}>{
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dashboard.info.ganancia_total)
                                                }</p>
                                                <p style={styles.infoText}>ganancia total</p>
                                            </div>
                                            <div className="col-md-6">
                                                <p style={styles.infoNumero}>{
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dashboard.info.ganancia_mes)
                                                }</p>
                                                <p style={styles.infoText}>ganancia este mes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Conteo rápido de compras</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_cobrar}</p>
                                                <p style={styles.infoText}>por cobrar</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_realizar}</p>
                                                <p style={styles.infoText}>por realizar</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_finalizar}</p>
                                                <p style={styles.infoText}>por finalizar</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card  bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Tipos de compras</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-2">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_productos['ef']}</p>
                                                <p style={styles.infoText}>Encuesta fake</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_productos['pa']}</p>
                                                <p style={styles.infoText}>Página amorosa</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_productos['tn']}</p>
                                                <p style={styles.infoText}>Tarjeta navideña</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_productos['tf']}</p>
                                                <p style={styles.infoText}>Tarjeta de cumpleaños</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_productos['pb']}</p>
                                                <p style={styles.infoText}>Página de boda</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p style={styles.infoNumero}>{dashboard.info.conteo_productos['er']}</p>
                                                <p style={styles.infoText}>Exámen de recuerdos</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div ></div > : <div></div>
    )
}

export default Dashboard;