
import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Spinner from 'react-loader-spinner'


const styles = {
    container: {
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(1,1,1,0.6)',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        textAlign: 'center'
    },
    logoText: {
        // marginTop: 20,
        fontWeight: 'bold',
        color: 'white'
    },
    logoContainer: {
        marginTop: -20
    }
}

const Loader = () => {

    return (
        <div style={styles.container}>
            <div style={styles.logo}>
                <img src="isotipo.png" className="d-inline-block my-4" alt="logo" width="80" />
                <div style={styles.logoContainer}>
                    <Spinner
                        type="ThreeDots"
                        color="#eb6363"
                        height={30}
                        width={30}
                    />
                </div>
                {/* <p style={styles.logoText}>Cargando...</p> */}
            </div>
        </div>
    )
}

export default Loader;
