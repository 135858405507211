import { AGREGAR_COMPRA, EDITAR_COMPRA, ELIMINAR_COMPRA, BUSCAR_COMPRAS, EDITANDO_COMPRA, GESTIONAR_COMPRAS, COBRAR_COMPRA, REALIZAR_COMPRA, FINALIZAR_COMPRA } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case AGREGAR_COMPRA:
            return [
                ...state.compras, payload
            ];
        case EDITAR_COMPRA:
            state.compras.find( compra => compra._id === payload).editing = false;
            return state;
        case EDITANDO_COMPRA:
            state.compras.find( compra => compra._id === payload).editing = true;
            return state;
        case ELIMINAR_COMPRA:
            return state.compras.filter( compra => compra._id !== payload.id )
        case BUSCAR_COMPRAS:
            return {
                ...state,
                compras: payload
            }
        case GESTIONAR_COMPRAS:
            return {
                ...state,
                compras_cobrar: payload.compras_cobrar,
                compras_realizar: payload.compras_realizar,
                compras_finalizar: payload.compras_finalizar
            }
        case COBRAR_COMPRA:
            return {
                ...state,
                compras_cobrar: payload.compras_cobrar,
                compras_realizar: payload.compras_realizar,
                compras_finalizar: payload.compras_finalizar
            }
        case REALIZAR_COMPRA:
            return {
                ...state,
                compras_cobrar: payload.compras_cobrar,
                compras_realizar: payload.compras_realizar,
                compras_finalizar: payload.compras_finalizar
            }
        case FINALIZAR_COMPRA:
            return {
                ...state,
                compras_cobrar: payload.compras_cobrar,
                compras_realizar: payload.compras_realizar,
                compras_finalizar: payload.compras_finalizar
            }
        default:
            return state;
    }
}