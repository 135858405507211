import { AGREGAR_COMPRA, EDITAR_COMPRA, EDITANDO_COMPRA, ELIMINAR_COMPRA, BUSCAR_COMPRAS, BUSCAR_PRODUCTOS, GESTIONAR_COMPRAS, COBRAR_COMPRA, REALIZAR_COMPRA, FINALIZAR_COMPRA } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';
import { changeLoader } from './loader';

export const buscarCompras = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/compras/`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: BUSCAR_COMPRAS,
            payload: res.data.compras
        });
        dispatch({
            type: BUSCAR_PRODUCTOS,
            payload: res.data.productos
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const gestionarCompras = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/compras/gestion`, config);
        dispatch(changeLoader(false));
        dispatch({
            type: GESTIONAR_COMPRAS,
            payload: res.data
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const agregarCompra = (compra) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(compra);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/compras/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_COMPRA,
            payload: res.data.compra
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editarCompra = (compra) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(compra);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/compras/editar/`, body, config);
        dispatch({
            type: EDITAR_COMPRA,
            payload: compra.id
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const editandoCompra = (id) => dispatch => {
    dispatch({
        type: EDITANDO_COMPRA,
        payload: id
    })
}

export const cobrarCompra = (compra) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(compra);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/compras/cobrar/`, body, config);
        dispatch({
            type: COBRAR_COMPRA,
            payload: res.data
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const realizarCompra = (compra) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(compra);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/compras/realizar/`, body, config);
        dispatch({
            type: REALIZAR_COMPRA,
            payload: res.data
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const finalizarCompra = (compra) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(compra);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/compras/finalizar/`, body, config);
        dispatch({
            type: FINALIZAR_COMPRA,
            payload: res.data
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

export const eliminarCompra = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id});
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/compras/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_COMPRA,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}